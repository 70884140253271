import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  createdBid: { status: 0, payload: null },
  setCreatedBid: action((state, payload = null) => {
    state.createdBid = payload
  }),
  createBid: thunk(async (actions, data) => {
    actions.setCreatedBid({ status: 1 })
    axios.post(`${API_URL}/bids`, data)
      .then(response => actions.setCreatedBid({ status: 2, payload: response.data }))
      .catch(e => actions.setCreatedBid({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
