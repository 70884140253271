import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  searchResults: { status: 0, payload: null },
  setSearchResults: action((state, payload = null) => {
    state.searchResults = payload
  }),
  search: thunk(async (actions, params) => {
    actions.setSearchResults({ status: 1 })
    axios.get(`${API_URL}/jobs/general_search/`, { params })
      .then(response => actions.setSearchResults({ status: 2, payload: response.data }))
      .catch(e => actions.setSearchResults({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  resultCount: null,
  setResultCount: action((state, payload = null) => {
    state.resultCount = payload
  })
}
