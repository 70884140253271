import { action } from 'easy-peasy'

export default {
    // used to set carousel on join for free
    joinForFreeSlide: 0,
    setJoinForFreeSlide: action((state, payload = null) => {
        state.joinForFreeSlide = payload
    }),
    // used for selecting request qoutation or exsiting services in direct hire module
    qouteOrService: '',
    setQouteOrService: action((state, payload = null) => {
        state.qouteOrService = payload
    }),
    // used when setting the search bar full
    searchBarFull: false,
    setSearchBarFull: action((state, payload = null) => {
        state.searchBarFull = payload
    }),
    // for gap modal trigger
    gapArtistDetails: null,
    setGapArtistDetails: action((state, payload = null) => {
        state.gapArtistDetails = payload
    }),
    PGAArtistDetails: null,
    setPGAArtistDetails: action((state, payload = null) => {
        state.PGAArtistDetails = payload
    })
}
