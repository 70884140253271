import { ClockCircleOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import axios from 'axios'
import React from 'react'
import { API_URL } from './constants'
import _ from 'lodash'

const excludedRoutes = [
  `${API_URL}/common/auth/login`,
  `${API_URL}/common/auth/signup`,
  `${API_URL}/common/auth/forgot_password`,
  `${process.env.REACT_APP_MAGPIE_API}/v1/tokens`,
]

const excludedGetRoutes = [
  `${API_URL}/jobs/general_search/`,
  `${API_URL}/common/users/`,
  `${API_URL}/packages`
]

console.log('excluded', excludedRoutes)
console.log('excluded', excludedGetRoutes)

// handles static countdown indicator
const handleTimer = (timer) => {
  if (timer > 0) {
    const countDownInterval = setInterval(() => {
      if (document.getElementById('countdown')) {
        window.document.getElementById('countdown').textContent = `${timer--}`
      }
      // to stop countdown at 0
      if (timer === -1) {
        clearInterval(countDownInterval)
      }
    }, 1000)
  }
}

const promptExpiredSession = () => {
  handleTimer(60)
  const auth = JSON.parse(localStorage.getItem('auth'))
  Modal.confirm({
    width: '442px',
    icon: <ClockCircleOutlined style={{ fontSize: 34 }} />,
    title: <h2>Your session is about to end</h2>,
    content: (
      <p>
        You have been inactive for a while. For your security, we will
        automatically sign you out in <b id='countdown' /> seconds. Do you want
        to stay signed in?
      </p>
    ),
    okButtonProps: { type: 'primary', width: 100 },
    cancelButtonProps: { type: 'default', width: 100 },
    cancelText: 'No, sign out',
    okText: 'Yes, stay signed in',
    onOk () {
      fetch(`${API_URL}/common/auth/refresh_token`, {
        method: 'POST',
        headers: {
          Authorization: auth.data.token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh_token: auth.data.refresh_token
        })
      })
        .then((response) => response.json())
        .then((json) => {
          if (typeof json.data !== 'undefined') {
            auth.data.refresh_token = json.data.refresh_token
            auth.data.token = json.data.token
            localStorage.setItem('auth', JSON.stringify(auth))
            window.location.reload()
          } else {
            window.location.href = '/auth'
            localStorage.clear()
          }
        })
        .catch((err) => {
          console.error(err)
          message.error('Oops, something went wrong..')
        })
    },
    onCancel () {
      window.location.href = '/auth'
      localStorage.clear()
    }
  })
}

export default {
  setupInterceptors (store) {
    // Request
    axios.interceptors.request.use(
      async (config) => {
        const auth = JSON.parse(localStorage.getItem('auth'))
        if (config.method === 'get' && excludedGetRoutes.indexOf(config.url) !== -1 && auth) {
          // Don't add the token for excluded GET requests
          return config;
        }
        if (excludedRoutes.indexOf(config.url) === -1 && auth) {
          config.headers.Authorization = auth.data.token
        }
        // config.headers.Host = HOST_NAME

        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )

    // Response
    axios.interceptors.response.use(
      (response) => { return response },
      (error) => {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.status !== 'undefined' &&
          error.response.status === 401 &&
          (error.response.data.error_code === 401006 ||
            error.response.data.error_code === 401005 ||
             error.response.data.error_code === 401001)
        ) {
          const hasAuth = JSON.parse(localStorage.getItem('auth'))
          if (typeof hasAuth !== 'undefined' && hasAuth) {
            _.once(promptExpiredSession())
          }
          setTimeout(() => {
            window.location.href = '/auth'
            localStorage.removeItem('auth')
            // for cases that is loggedin when we deployed change of storage
            sessionStorage.removeItem('auth')
          }, 60000)
        }

        return Promise.reject(error)
      }
    )
  }
}
