import { thunk, action } from 'easy-peasy'
import { API_URL, ADMIN_API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Verify Email
  verifyEmail: { status: 0, payload: null },
  setPostVerifyEmail: action((state, payload = null) => {
    state.verifyEmail = payload
  }),
  postVerifyEmail: thunk(async (actions, data) => {
    actions.setPostVerifyEmail({ status: 1 })
    axios
      .post(`${API_URL}/common/users/self/verify_email`, data)
      .then((response) => actions.setPostVerifyEmail({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPostVerifyEmail({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Update Image
  updatedImage: { status: 0, payload: null },
  setPutUpdateImage: action((state, payload = null) => {
    state.updatedImage = payload
  }),
  putUpdateImage: thunk(async (actions, data) => {
    actions.setPutUpdateImage({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/image`, data)
      .then((response) => actions.setPutUpdateImage({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPutUpdateImage({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Update Profile
  updatedProfile: { status: 0, payload: null },
  setPutUpdateProfile: action((state, payload = null) => {
    state.updatedProfile = payload
  }),
  putUpdateProfile: thunk(async (actions, data) => {
    actions.setPutUpdateProfile({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/update_profile`, data)
      .then((response) => actions.setPutUpdateProfile({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPutUpdateProfile({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Update Email
  updateEmail: { status: 0, payload: null },
  setPutUpdateEmail: action((state, payload = null) => {
    state.updateEmail = payload
  }),
  putUpdateEmail: thunk(async (actions, data) => {
    actions.setPutUpdateEmail({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/change_email`, data)
      .then((response) => actions.setPutUpdateEmail({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPutUpdateEmail({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Get self
  self: { status: 0, payload: null },
  setGetSelf: action((state, payload = null) => {
    state.self = payload
  }),
  getSelf: thunk(async (actions, params) => {
    actions.setGetSelf({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self`, { params })
      .then((response) => actions.setGetSelf({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setGetSelf({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Update Cover Image
  updatedCover: { status: 0, payload: null },
  setPutUpdateCover: action((state, payload = null) => {
    state.updatedCover = payload
  }),
  putUpdateCover: thunk(async (actions, data) => {
    actions.setPutUpdateCover({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/cover_image`, data)
      .then((response) => actions.setPutUpdateCover({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPutUpdateCover({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Edit Profile Active
  editProfileActive: 0,
  setEditProfileActive: action((state, payload = null) => {
    state.editProfileActive = payload
  }),
  // Change Password
  changedPassword: { status: 0, payload: null },
  setChangePassword: action((state, payload = null) => {
    state.changedPassword = payload
  }),
  putChangePassword: thunk(async (actions, data) => {
    actions.setChangePassword({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/change_password`, data)
      .then((response) => actions.setChangePassword({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setChangePassword({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Get User
  user: { status: 0, payload: null },
  setUser: action((state, payload = null) => {
    state.user = payload
  }),
  getUser: thunk(async (actions, id) => {
    actions.setUser({ status: 1 })
    axios
      .get(`${API_URL}/common/users/${id}`)
      .then((response) => actions.setUser({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setUser({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Get my packages
  myPackages: { status: 0, payload: null },
  setMyPackages: action((state, payload = null) => {
    state.myPackages = payload
  }),
  getMyPackages: thunk(async (actions) => {
    actions.setMyPackages({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/my_packages`)
      .then((response) => actions.setMyPackages({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setMyPackages({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // My Projects
  myProjects: { status: 0, payload: null },
  setMyProjects: action((state, payload = null) => {
    state.myProjects = payload
  }),
  getMyProjects: thunk(async (actions, params) => {
    actions.setMyProjects({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/my_jobs`, { params })
      .then((response) => actions.setMyProjects({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setMyProjects({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Clients projects
  clientProjects: { status: 0, payload: null },
  setClientProjects: action((state, payload = null) => {
    state.clientProjects = payload
  }),
  getClientProjects: thunk(async (actions, params) => {
    actions.setClientProjects({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/my_created_jobs`, { params })
      .then((response) => actions.setClientProjects({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setClientProjects({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Get Users
  users: { status: 0, payload: null },
  setUsers: action((state, payload = null) => {
    state.users = payload
  }),
  getUsers: thunk(async (actions, params) => {
    actions.setUsers({ status: 1 })
    axios
      .get(`${API_URL}/common/users`, { params })
      .then((response) => actions.setUsers({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setUsers({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Is Me
  isMe: false,
  setIsMe: action((state, payload) => {
    state.isMe = payload
  }),
  // Upload Tax Status(registration)
  taxRegistration: { status: 0, payload: null },
  setTaxRegistration: action((state, payload = null) => {
    state.taxRegistration = payload
  }),
  putTaxRegistration: thunk(async (actions, data) => {
    actions.setTaxRegistration({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/tax_registration`, data)
      .then((response) => actions.setTaxRegistration({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setTaxRegistration({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Add payout details
  addedPayoutDetails: { status: 0, payload: null },
  setAddedPayoutDetails: action((state, payload = null) => {
    state.addedPayoutDetails = payload
  }),
  addPayoutDetails: thunk(async (actions, data) => {
    actions.setAddedPayoutDetails({ status: 1 })
    axios
      .post(`${API_URL}/common/users/self/payout_details`, data)
      .then((response) => actions.setAddedPayoutDetails({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setAddedPayoutDetails({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Add payout details
  updatedPayoutDetails: { status: 0, payload: null },
  setUpdatedPayoutDetails: action((state, payload = null) => {
    state.updatedPayoutDetails = payload
  }),
  updatePayoutDetails: thunk(async (actions, id, data) => {
    actions.setUpdatedPayoutDetails({ status: 1 })
    axios
      .put(`${API_URL}/common/users/self/payout_details/${id}`, data)
      .then((response) => actions.setUpdatedPayoutDetails({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setUpdatedPayoutDetails({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Add payout details
  deletedPayoutDetails: { status: 0, payload: null },
  setDeletedPayoutDetails: action((state, payload = null) => {
    state.deletedPayoutDetails = payload
  }),
  deletePayoutDetails: thunk(async (actions, id) => {
    actions.setDeletedPayoutDetails({ status: 1 })
    axios
      .delete(`${API_URL}/common/users/self/payout_details/${id}`)
      .then((response) => actions.setDeletedPayoutDetails({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setDeletedPayoutDetails({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Get payout details
  payoutDetails: { status: 0, payload: null },
  setPayoutDetails: action((state, payload = null) => {
    state.payoutDetails = payload
  }),
  getPayoutDetails: thunk(async (actions) => {
    actions.setPayoutDetails({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/payout_details`)
      .then((response) => actions.setPayoutDetails({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setPayoutDetails({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Withdrawal
  withdrawal: { status: 0, payload: null },
  setWithdrawal: action((state, payload = null) => {
    state.withdrawal = payload
  }),
  withdraw: thunk(async (actions, data) => {
    actions.setWithdrawal({ status: 1 })
    axios
      .post(`${API_URL}/common/users/self/withdrawal`, data)
      .then((response) => actions.setWithdrawal({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setWithdrawal({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Earnings Summary
  earningsSummary: { status: 0, payload: null },
  setEarningsSummary: action((state, payload = null) => {
    state.earningsSummary = payload
  }),
  getEarningsSummary: thunk(async (actions) => {
    actions.setEarningsSummary({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/earnings_summary`)
      .then((response) => actions.setEarningsSummary({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setEarningsSummary({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Earnings Transactions
  earningsTransactions: { status: 0, payload: null },
  setEarningsTransactions: action((state, payload = null) => {
    state.earningsTransactions = payload
  }),
  getEarningsTransactions: thunk(async (actions) => {
    actions.setEarningsTransactions({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/earnings_transactions`)
      .then((response) => actions.setEarningsTransactions({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setEarningsTransactions({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Retrieve Withdrawal
  withdrawalSummary: { status: 0, payload: null },
  setWithdrawalSummary: action((state, payload = null) => {
    state.withdrawalSummary = payload
  }),
  getWithdrawalSummary: thunk(async (actions) => {
    actions.setWithdrawalSummary({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/withdrawal`)
      .then((response) => actions.setWithdrawalSummary({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setWithdrawalSummary({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Client Charges
  clientCharges: { status: 0, payload: null },
  setClientCharges: action((state, payload = null) => {
    state.clientCharges = payload
  }),
  getClientCharges: thunk(async (actions) => {
    actions.setClientCharges({ status: 1 })
    axios
      .get(`${API_URL}/receipts/payor`)
      .then((response) => actions.setClientCharges({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setEarningsTransactions({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Artist's receipt
  artistEarnings: { status: 0, payload: null },
  setArtistEarnings: action((state, payload = null) => {
    state.artistEarnings = payload
  }),
  getArtistEarnings: thunk(async (actions) => {
    actions.setArtistEarnings({ status: 1 })
    axios
      .get(`${API_URL}/receipts/payee`)
      .then((response) => actions.setArtistEarnings({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setArtistEarnings({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // user search
  // Get Users
  userSearch: { status: 0, payload: null },
  setUserSearch: action((state, payload = null) => {
    state.userSearch = payload
  }),
  searchUsers: thunk(async (actions, data) => {
    actions.setUserSearch({ status: 1 })
    axios
      .get(`${API_URL}/common/users/?search=?${data}`)
      .then((response) => actions.setUserSearch({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setUserSearch({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // get jobs that users has bids on
  myJobBids: { status: 0, payload: null },
  setMyJobBids: action((state, payload = null) => {
    state.myJobBids = payload
  }),
  getMyJobBids: thunk(async (actions, params) => {
    actions.setMyJobBids({ status: 1 })
    axios
      .get(`${API_URL}/common/users/self/my_job_bids?page_size=${params.page_size}`)
      .then((response) => actions.setMyJobBids({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setMyJobBids({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  homeBanners: { status: 0, payload: null },
  setGetHomeBanners: action((state, payload = null) => {
    state.homeBanners = payload
  }),
  getHomeBanners: thunk(async (actions, params) => {
    actions.setGetHomeBanners({ status: 1 })
    axios
      .get(`${ADMIN_API_URL}/home-banners`, { params })
      .then((response) => {
        actions.setGetHomeBanners({ status: 2, payload: response.data })
      })
      .catch((e) =>
        actions.setGetHomeBanners({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // get user by user code you can hit this endpoint even without auth token
  userByCode: { status: 0, payload: null },
  setGetUserByCode: action((state, payload = null) => {
    state.userByCode = payload
  }),
  getUserByCode: thunk(async (actions, userCode) => {
    actions.setGetUserByCode({ status: 1 })
    axios
      .get(`${API_URL}/common/users/profile/${userCode}`)
      .then((response) => actions.setGetUserByCode({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setGetUserByCode({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // rate related actions
  userRating: { status: 0, payload: null },
  setGetUserRating: action((state, payload = null) => {
    state.userRating = payload
  }),
  getUserRating: thunk(async (actions, id) => {
    actions.setGetUserRating({ status: 1 })
    axios.get(`${API_URL}/common/users/${id}/reviews`)
      .then(response => actions.setGetUserRating({ status: 2, payload: response.data }))
      .catch(e => actions.setGetUserRating({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
