import { action, thunk } from 'easy-peasy'
import Xendit from 'xendit-node'
import { CURRENCY, CARD_VERIFIED, CARD_IN_REVIEW, CARD_FAILED } from '@/utils/constants'
import axios from 'axios'

// statuses
// 1 = idle
// 2 = fulfilled
// 3 = failed
// 4 = in review

export default {
  addedXenditCard: { status: 0, payload: null },
  setAddedXenditCard: action((state, payload = null) => {
    state.addedXenditCard = payload
  }),
  addXenditCard: thunk(async (actions, data) => {
    actions.setAddedXenditCard({ status: 1, payload: null })
    window.Xendit.card.createToken(data, (error, creditCardToken) => {
        if (error) {
            actions.setAddedXenditCard({
                status: 3,
                payload: typeof error?.message !== 'undefined' ? error.message : 'Invalid Card Details.'
            })
        }
        else{
            if (creditCardToken.status === CARD_VERIFIED) {
                actions.setAddedXenditCard({ status: 2, payload: creditCardToken })
            } else if (creditCardToken.status === CARD_IN_REVIEW) {
                action.setAddedXenditCard({ status: 4, payload: creditCardToken.payer_authentication_url })
            } else if (creditCardToken.status === CARD_FAILED) {
                actions.setAddedXenditCard({ status: 3, payload: typeof creditCardToken.failure_reason !== 'undefined' ? creditCardToken.failure_reason : '' })
            }
        }
      })
  }),
//   card authentication
  authenticatedCard: { status: 0, payload: null },
  setAuthenticatedCard: action((state, payload = null) => {
      state.authenticatedCard = payload
  }),
  authenticateCard: thunk(async (actions, data) => {
    console.error("Authenticating Card")
    actions.setAuthenticatedCard({ status: 1, payload: null })
    data.currency = CURRENCY
    window.Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PK)
    window.Xendit.card.createAuthentication(data, (error, data) => {
        if (error) {
            actions.setAuthenticatedCard({
                status: 3,
                payload: error
            })
        } else {
            if (data.status === CARD_VERIFIED) {
                actions.setAuthenticatedCard({ status: 2, payload: data })
            } else if (data.status === CARD_IN_REVIEW) {
                actions.setAuthenticatedCard({ status: 4, payload: data })
            } else if (data.status === CARD_FAILED) {
                actions.setAuthenticatedCard({ status: 3, payload: data })
            }
        }
    })
  }),

//   crate charge
  chargedCard: { status: 0, payload: null },
  setChargedCard: action((state, payload = null) => {
      state.chargedCard = payload
  }),
  chargeCard: thunk(async (actions, data) => {
      actions.setChargedCard({ status: 1, payload: null })
      axios.post(`${process.env.REACT_APP_XENDIT_API_URL}/credit_card_charges`, data, {
        auth: {
            username: process.env.REACT_APP_XENDIT_SK,
            password: ''
        }
      })
        .then(response => {
            console.log('response', response)
        })
        .catch(e => {
            console.log('error', e)
        })
  })
}
