import { createStore } from 'easy-peasy'
import auth from './auth'
import users from './users'
import project from './project'
import service from './service'
import portfolio from './portfolio'
import message from './message'
import magpie from './magpie'
import payment from './payment'
import bid from './bid'
import upload from './upload'
import admin from './admin'
import misc from './misc'
import search from './search'
import xendit from './xendit'

export default createStore({
  ...auth,
  ...users,
  ...project,
  ...service,
  ...portfolio,
  ...message,
  ...magpie,
  ...payment,
  ...bid,
  ...upload,
  ...admin,
  ...misc,
  ...search,
  ...xendit
})
