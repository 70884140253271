import { thunk, action } from 'easy-peasy'
import axios from 'axios'

export default {
  addedMagpieCreditCard: { status: 0, payload: null },
  setAddedMagpieCreditCard: action((state, payload = null) => {
    state.addedMagpieCreditCard = payload
  }),
  addMagpieCreditCard: thunk(async (actions, data) => {
    const token = window.btoa(`${process.env.REACT_APP_MAGPIE_PK}:`)
    actions.setAddedMagpieCreditCard({ status: 1 })
    axios.post(`${process.env.REACT_APP_MAGPIE_API}/v1/tokens`, data, {
      headers: { Authorization: `Basic ${token}` }
    })
      .then(response => {
        actions.setAddedMagpieCreditCard({ status: 2, payload: response.data })
      })
      .catch(e => {
        if (typeof e.response === 'undefined') {
          actions.setAddedMagpieCreditCard({ status: 3, payload: e })
        } else {
          actions.setAddedMagpieCreditCard({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e })
        }
      })
  })
}
