import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  addedBankAccount: { status: 0, payload: null },
  setAddedBankAccount: action((state, payload = null) => {
    state.addedBankAccount = payload
  }),
  addBankAccount: thunk(async (actions, data) => {
    actions.setAddedBankAccount({ status: 1 })
    axios.post(`${API_URL}/payments/credit_cards`, data)
      .then(response => actions.setAddedBankAccount({ status: 2, payload: response.data }))
      .catch(e => actions.setAddedBankAccount({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  addedCreditCard: { status: 0, payload: null },
  setAddedCreditCard: action((state, payload = null) => {
    state.addedCreditCard = payload
  }),
  addCreditCard: thunk(async (actions, data) => {
    actions.setAddedCreditCard({ status: 1 })
    axios.post(`${API_URL}/payments/credit_cards`, data)
      .then(response => actions.setAddedCreditCard({ status: 2, payload: response.data }))
      .catch(e => actions.setAddedCreditCard({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  removedCreditCard: { status: 0, payload: null },
  setRemovedCreditCard: action((state, payload = null) => {
    state.removedCreditCard = payload
  }),
  removeCreditCard: thunk(async (actions, id) => {
    actions.setRemovedCreditCard({ status: 1 })
    axios.delete(`${API_URL}/payments/${id}/credit_cards`)
      .then(response => actions.setRemovedCreditCard({ status: 2, payload: response.data }))
      .catch(e => actions.setRemovedCreditCard({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  updatedPaymentOption: { status: 0, payload: null },
  setUpdatedCreditCard: action((state, payload = null) => {
    state.updatedPaymentOption = payload
  }),
  setDefaultPaymentOption: thunk(async (actions, id) => {
    actions.setUpdatedCreditCard({ status: 1 })
    axios.put(`${API_URL}/payments/${id}/credit_cards`)
      .then(response => actions.setUpdatedCreditCard({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedCreditCard({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Payments Page Active
  paymentsPageActive: 0,
  setPaymentsPageActive: action((state, payload = null) => {
    state.paymentsPageActive = payload
  })
}
