import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Create Message
  createdMessage: { status: 0, payload: null },
  setCreatedMessage: action((state, payload = null) => {
    state.createdMessage = payload
  }),
  postCreateMessage: thunk(async (actions, data) => {
    actions.setCreatedMessage({ status: 1 })
    axios
      .post(`${API_URL}/messages`, data)
      .then((response) => actions.setCreatedMessage({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setCreatedMessage({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Create Multiple Message
  createdMultipleMessage: { status: 0, payload: null },
  setCreatedMultipleMessage: action((state, payload = null) => {
    state.createdMultipleMessage = payload
  }),
  postCreateMultipleMessage: thunk(async (actions, data) => {
    actions.setCreatedMultipleMessage({ status: 1 })
    axios
      .post(`${API_URL}/messages/multiple`, data)
      .then((response) => actions.setCreatedMultipleMessage({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setCreatedMultipleMessage({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // List Messages
  messages: { status: 0, payload: null },
  setListMessages: action((state, payload = null) => {
    state.messages = payload
  }),
  listMessages: thunk(async (actions, params) => {
    actions.setListMessages({ status: 1 })
    axios
      .get(`${API_URL}/messages`, { params })
      .then((response) => actions.setListMessages({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setListMessages({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Mark Message as Read
  markedAsRead: { status: 0, payload: null },
  setMarkedAsRead: action((state, payload = null) => {
    state.markedAsRead = payload
  }),
  putMarkAsRead: thunk(async (actions, id) => {
    actions.setMarkedAsRead({ status: 1 })
    axios
      .put(`${API_URL}/messages/${id}`)
      .then((response) => actions.setMarkedAsRead({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setMarkedAsRead({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // List Messages
  messagesThread: { status: 0, payload: null },
  setListMessagesThread: action((state, payload = null) => {
    state.messagesThread = payload
  }),
  listMessagesThread: thunk(async (actions, params) => {
    actions.setListMessagesThread({ status: 1 })
    axios
      .get(`${API_URL}/messages/thread`, { params })
      .then((response) => actions.setListMessagesThread({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setListMessagesThread({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // user is typing filter
  userTyping: { status: 0, payload: null },
  setUserTyping: action((state, payload = null) => {
    state.userTyping = payload
  }),
  postUserTyping: thunk(async (actions, data) => {
    actions.setUserTyping({ status: 1 })
    axios
      .post(`${API_URL}/messages/typing`, data)
      .then((response) => actions.setUserTyping({ status: 2, payload: response.data }))
      .catch((e) =>
        actions.setUserTyping({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  }),
  // Messages Details
  messageDetails: null,
  setMessageDetails: action((state, payload = null) => {
    state.messageDetails = payload
  }),
  // attachments
  // List Messages
  attachments: { status: 0, payload: null },
  setAttachments: action((state, payload = null) => {
    state.attachments = payload
  }),
  // interval controller // Messages Details
  intervalState: null,
  setIntervalState: action((state, payload = null) => {
    state.messageDetails = payload
  }),
  // new direct message?
  isNewDirectMessage: false,
  setIsNewDirectMessage: action((state, payload = null) => {
    state.isNewDirectMessage = payload
  }),
  // filter for direct or project message
  isDirectMessage: true,
  setIsDirectMessage: action((state, payload = null) => {
    state.isDirectMessage = payload
  }),
  directMessageWith: '',
  setNewDirectMessageWith: action((state, payload = null) => {
    state.directMessageWith = payload
  }),
  // message front end filter / sort
  threadFilter: 'all',
  setThreadFilter: action((state, payload = null) => {
    state.threadFilter = payload
  }),
  proposedPackage: null,
  setProposedPackage: action((state, payload = null) => {
    state.proposedPackage = payload
  })
}
