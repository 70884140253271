import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Signin
  signin: { status: 0, payload: null },
  setPostSignin: action((state, payload = null) => {
    state.signin = payload
  }),
  postSignin: thunk(async (actions, data) => {
    actions.setPostSignin({ status: 1 })
    axios.post(`${API_URL}/common/auth/login`, data)
      .then(response => {
        actions.setPostSignin({ status: 2, payload: response.data })
      })
      .catch(e => {
        actions.setPostSignin({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e })
      })
  }),
  auth: JSON.parse(localStorage.getItem('auth')),
  setAuth: action((state, payload = null) => {
    if (payload) {
      state.auth = payload
      localStorage.setItem('auth', JSON.stringify(payload))
    }
  }),
  // Signup
  signup: { status: 0, payload: null },
  setPostSignup: action((state, payload = null) => {
    state.signup = payload
  }),
  postSignup: thunk(async (actions, data) => {
    actions.setPostSignup({ status: 1 })
    axios.post(`${API_URL}/common/auth/signup`, data)
      .then(response => actions.setPostSignup({ status: 2, payload: response.data }))
      .catch(e => actions.setPostSignup({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Forgot Password
  forgotPassword: { status: 0, payload: null },
  setPostForgotPassword: action((state, payload = null) => {
    state.forgotPassword = payload
  }),
  postForgotPassword: thunk(async (actions, data) => {
    actions.setPostForgotPassword({ status: 1 })
    axios.post(`${API_URL}/common/auth/forgot_password`, data)
      .then(response => actions.setPostForgotPassword({ status: 2, payload: response.data }))
      .catch(e => actions.setPostForgotPassword({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Mask Email
  maskedEmail: '',
  maskEmail: action((state, payload = '') => {
    state.maskedEmail = payload.replace(/^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    )
  }),
  // Refresh token
  refreshToken: { status: 0, payload: null },
  setRefreshToken: action((state, payload = null) => {
    state.refreshToken = payload
  }),
  postRefreshToken: thunk(async (actions, data) => {
    actions.setRefreshToken({ status: 1 })
    axios.post(`${API_URL}/common/auth/refresh_token`, data)
      .then(response => actions.setRefreshToken({ status: 2, payload: response.data }))
      .catch(e => actions.setRefreshToken({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
