import { thunk, action } from 'easy-peasy'
import { UPLOAD_URL, API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  file: { status: 0, payload: null },
  setUploadedfile: action((state, payload = null) => {
    state.file = payload
  }),
  uploadFile: thunk(async (actions, data) => {
    actions.setUploadedfile({ status: 1, payload: null })
    axios.post(`${UPLOAD_URL}/uploads`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => actions.setUploadedfile({ status: 2, payload: response.data }))
      .catch(e => actions.setUploadedfile({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),

  // get signed url
  signedUrl: { status: 0, payload: null },
  setSignedUrl: action((state, payload = null) => {
    state.signedUrl = payload
  }),
  getSignedUrl: thunk(async (actions, params) => {
    actions.setSignedUrl({ status: 1 })
    axios.get(`${API_URL}/uploads/?s3_resource=${params}`)
      .then(response => actions.setSignedUrl({ status: 2, payload: response.data }))
      .catch(e => actions.setSignedUrl({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
