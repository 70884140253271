import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Container = ({ children }) => {
  const history = useHistory()
  const [onMobile, setOnMobile] = useState(false)

  useEffect(() => {
    // width came from header mobile responsiveness
    if (window.innerWidth <= 1438) {
      setOnMobile(true)
    } else {
      history.push('/')
    }
  }, [window.innerWidth])

  return onMobile && children({})
}

export default Container
