import { thunk, action } from 'easy-peasy'
import { ADMIN_API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  pageContent: { status: 0, payload: null },
  setPageContent: action((state, payload = null) => {
    state.pageContent = payload
  }),
  getPageContent: thunk(async (actions, slug) => {
    actions.setPageContent({ status: 1 })
    axios
      .get(`${ADMIN_API_URL}/pages/slug/${slug}`, {})
      .then((response) => {
        actions.setPageContent({ status: 2, payload: response.data })
      })
      .catch((e) =>
        actions.setPageContent({
          status: 3,
          payload: typeof e.response !== 'undefined' ? e.response.data : e
        })
      )
  })
}
