import axios from 'axios'
import { jobTypes } from './constants'
import { UPLOAD_URL, CLOUD_FRONT_URL, API_URL, validImageTypes, timelines, projectPriceRange } from '@/utils/constants'
import MobileCodes from './mobile_service'
import moment from 'moment'

export const getJobTypeName = (code) => {
  const row = jobTypes.filter((job) => job.code === code)
  return row[0].value || code
}

export const getFilename = (url) =>
  typeof url !== 'undefined' && url.substring(url.lastIndexOf('/') + 1)

export const getSignedUrlFilename = (url) => {
  return url.substring(url.lastIndexOf('/') + 1).split('?')[0]
}

export const getFileExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}
export const bg = () => {
  const bgs = [
    `${CLOUD_FRONT_URL}static/assets/images/bg/bggreen.png`,
    `${CLOUD_FRONT_URL}static/assets/images/bg/bgpurple.png`,
    `${CLOUD_FRONT_URL}static/assets/images/bg/bgblack.png`,
    `${CLOUD_FRONT_URL}static/assets/images/bg/bgpeach.png`
  ]
  return bgs[Math.floor(Math.random() * bgs.length)]
}

export const uploadFileHelper = (data) => {
  return axios.post(`${UPLOAD_URL}/uploads`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const formatAMPM = (dateString, timeOnly = true) => {
  const date = new Date(dateString)
  const currentDate = new Date()

  const isSameDay =
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  const fullDate = date.toDateString()

  let hours = date.getHours()
  let minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes

  let strTime = isSameDay
    ? `${hours}:${minutes} ${ampm}`
    : `${fullDate} ${hours}:${minutes} ${ampm}`
  if (timeOnly) {
    strTime = `${hours}:${minutes} ${ampm}`
  }
  return strTime
}

export const groupByDate = (dateString, dateCheck) => {
  const date = new Date(dateString)
  const date2 = new Date(dateCheck)

  const finalCurrDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
  const finalCheckDate = date2.getDate() + '-' + (date2.getMonth() + 1) + '-' + date2.getFullYear()

  if (finalCurrDate === finalCheckDate) {
    return ''
  } else {
    return `${moment(date).format('MMMM D YYYY')} at ${moment(date).format('hh:mm A')}`
    // legacy implementation
    // return date.toLocaleString('default', {
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric'
    // })
  }
}

export const formatDateOnly = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

export const appendCloudFront = (url) => url && `${CLOUD_FRONT_URL}${url}`

export const rateUser = (id, data) => {
  return axios.post(`${API_URL}/common/users/${id}/reviews`, data, {
    headers: {
      Accept: 'application/json'
    }
  })
}

export const getYoutubeThumbnail = (url) => {
  let videoId = url.split('v=')[1]
  const ampersandPos = typeof videoId !== 'undefined' && videoId.indexOf('&')
  if (ampersandPos !== -1 && typeof videoId !== 'undefined') {
    videoId = videoId.substring(0, ampersandPos)
    return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
  } else {
    return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
  }
}

export const isImage = (file) => validImageTypes.indexOf(getFileExtension(file)) !== -1

export const isPdf = (file) => ['pdf, PDF'].indexOf(getFileExtension(file)) !== -1

export const isEmbedCode = (file) => typeof file === 'object'

export const openPdf = (pdf) => window.open(appendCloudFront(pdf), '_blank', 'noreferrer')

// used to get prefix ex: +639123456789 -> { name: 'Philippines', dial_code: '+63' ... rest}
export const getMobilePrefix = (mobile) => {
  let hasMatch = null

  MobileCodes.forEach(codes => {
    if (mobile.includes(codes.dial_code)) {
      hasMatch = codes
    }
  })

  return hasMatch !== null && hasMatch
}

// used for truncating mobile from prefix for mobile ex: +639123456789 -> 9123456789
export const formatMobileNumber = (mobile) => {
  let truncatedMobile = null

  MobileCodes.forEach(codes => {
    if (mobile.includes(codes.dial_code)) {
      truncatedMobile = mobile.replace(codes.dial_code, '')
    }
  })

  return truncatedMobile !== null && truncatedMobile
}

// pass setter
export const setInitialMobilePrefix = (setter, mobile) => {
  if (typeof mobile !== 'undefined' && typeof setter !== 'undefined') {
    const mobilePrefix = getMobilePrefix(mobile)
    const match = MobileCodes.filter(codes => codes.dial_code === mobilePrefix.dial_code)

    return setter(match[0].dial_code)
  }
}

export const toSentenceCase = (str) => String(str).charAt(0).toUpperCase() + String(str).slice(1)

export const generateQueryString = (query) => {
  if (typeof query === 'object') { return Object.keys(query).map(keys => `${keys}=${query[keys]}`).join('&') }
}

export const getTimeline = (value) => value && timelines.filter(timeline => timeline.code === value)[0].title

export const getBudget = (value, asPreview = false) => {
  if (value) {
    if (!asPreview) {
      const { max_budget: maxBudget, min_budget: minBudget } = projectPriceRange.filter(range => range.code === value)[0]

      return { max_budget: maxBudget, min_budget: minBudget }
    } else {
      return projectPriceRange.filter(range => range.code === value)[0].label
    }
  }
}

// export const isDirectHire = (project) => project.status === 2 && typeof project.payload.data.inspiration !== 'undefined'
export const isDirectHire = (project) => false

export const composePortfolio = (portfolios) => {
  if (portfolios.length > 1) {
    return portfolios.map(portfolio => portfolio.image_urls[0]).splice(0, 4) || []
  } else {
    return portfolios[0].image_urls.slice(0, 4) || []
  }
}
