import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Container = ({ children }) => {
  const history = useHistory()
  useEffect(() => {
    if (!localStorage.getItem('auth') || typeof localStorage.getItem('auth') === 'undefined') {
      history.push('/auth')
    }
  }, [localStorage.getItem('auth')])
  return children({})
}

export default Container
