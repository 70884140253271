import { API_URL } from '@/utils/constants'
import axios from 'axios'
import { action, thunk } from 'easy-peasy'

export default {
  // Service Active Form
  serviceActiveForm: 'serviceDetails',
  setServiceActiveForm: action((state, payload = null) => {
    state.serviceActiveForm = payload
  }),
  // Create Package
  createdPackage: { status: 0, payload: null },
  setPostCreatePackage: action((state, payload = null) => {
    state.createdPackage = payload
  }),
  postCreatePackage: thunk(async (actions, data) => {
    actions.setPostCreatePackage({ status: 1 })
    axios.post(`${API_URL}/packages`, data)
      .then(response => actions.setPostCreatePackage({ status: 2, payload: response.data }))
      .catch(e => actions.setPostCreatePackage({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Update Package
  updatedPackage: { status: 0, payload: null },
  setUpdatedPackage: action((state, payload = null) => {
    state.updatedPackage = payload
  }),
  putUpdatePackage: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setUpdatedPackage({ status: 1 })
    axios.put(`${API_URL}/packages/${id}`, data)
      .then(response => actions.setUpdatedPackage({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedPackage({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Upload Package Image
  packageImage: { status: 0, payload: null },
  setPackageImage: action((state, payload = null) => {
    state.packageImage = payload
  }),
  putPackageImage: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setPackageImage({ status: 1 })
    axios.put(`${API_URL}/packages/${id}/upload`, data)
      .then(response => actions.setPackageImage({ status: 2, payload: response.data }))
      .catch(e => actions.setPackageImage({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Recent Uploaded Package Image
  recentPackageImage: '',
  setRecentPackageImage: action((state, payload = null) => {
    state.recentPackageImage = payload
  }),
  // Get Packages
  packages: { status: 0, payload: null },
  setPackages: action((state, payload = null) => {
    state.packages = payload
  }),
  getPackages: thunk(async (actions, params) => {
    actions.setPackages({ status: 1 })
    axios.get(`${API_URL}/packages`, { params })
      .then(response => actions.setPackages({ status: 2, payload: response.data }))
      .catch(e => actions.setPackages({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Get Package
  packageDetails: { status: 0, payload: null },
  setPackageDetails: action((state, payload = null) => {
    state.packageDetails = payload
  }),
  getPackageDetails: thunk(async (actions, id) => {
    actions.setPackageDetails({ status: 1 })
    axios.get(`${API_URL}/packages/${id}`)
      .then(response => actions.setPackageDetails({ status: 2, payload: response.data }))
      .catch(e => actions.setPackageDetails({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Service Modal
  offerServiceModal: null,
  setOfferServiceModal: action((state, payload = null) => {
    state.offerServiceModal = payload
  }),
  // Invite Artist
  inviteArtistModal: null,
  setInviteArtistModal: action((state, payload = null) => {
    state.inviteArtistModal = payload
  }),
  // Get User Packages
  userPackages: { status: 0, payload: null },
  setUserPackages: action((state, payload = null) => {
    state.userPackages = payload
  }),
  getUserPackages: thunk(async (actions, userId) => {
    actions.setUserPackages({ status: 1 })
    axios.get(`${API_URL}/packages/user/${userId}`)
      .then(response => actions.setUserPackages({ status: 2, payload: response.data }))
      .catch(e => {
        actions.setUserPackages({ status: 3, payload: e })
      })
  }),
  // DELETE Packages
  deletedPackages: { status: 0, payload: null },
  setDeletedPackages: action((state, payload = null) => {
    state.deletedPackages = payload
  }),
  deletePackages: thunk(async (actions, id) => {
    actions.setDeletedPackages({ status: 1 })
    axios.delete(`${API_URL}/packages/${id}`)
      .then(response => actions.setDeletedPackages({ status: 2, payload: response.data }))
      .catch(e => actions.setDeletedPackages({ status: 3, payload: e.response.data }))
  }),
  // Get Package By Id
  packageById: {},
  setPackageById: action((state, data = {}) => {
    state.packageById = { ...state.packageById, ...data }
  }),
  getPackageById: thunk(async (actions, id) => {
    actions.setPackageById({ [id]: { status: 1, payload: null } })
    axios.get(`${API_URL}/packages/${id}`)
      .then(response => actions.setPackageById({
        [id]: { status: 2, payload: response.data }
      }))
      .catch(e => actions.setPackageById({
        [id]: {
          status: 3,
          payload: typeof e.response !== 'undefined'
            ? e.response.data
            : e
        }
      }))
  }),
  // SelectedPublicTier
  selectedPublicTier: '',
  setSelectedPublicTier: action((state, payload = null) => {
    state.selectedPublicTier = payload
  }),
  // Add custom package to a service
  customPackage: { status: 0, payload: null },
  setCustomPackage: action((state, payload = null) => {
    state.customPackage = payload
  }),
  putCustomPackage: thunk(async (actions, payload) => {
    const { id, data } = payload
    actions.setCustomPackage({ status: 1 })
    axios.put(`${API_URL}/packages/${id}/custom`, data)
      .then(response => actions.setCustomPackage({ status: 2, payload: response.data }))
      .catch(e => actions.setCustomPackage({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
