import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Service Active Form
  portfolioActiveForm: 'formIntro',
  setPortfolioActiveForm: action((state, payload = null) => {
    state.portfolioActiveForm = payload
  }),
  // Create Portfolio
  createdPortfolio: { status: 0, payload: null },
  setCreatedPortfolio: action((state, payload = null) => {
    state.createdPortfolio = payload
  }),
  putCreatePortfolio: thunk(async (actions, payload) => {
    actions.setCreatedPortfolio({ status: 1 })
    axios.put(`${API_URL}/common/users/self/portfolio/upload`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => actions.setCreatedPortfolio({ status: 2, payload: response.data }))
      .catch(e => actions.setCreatedPortfolio({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Update Portfolio
  updatedPortfolio: { status: 0, payload: null },
  setUpdatedPortfolio: action((state, payload = null) => {
    state.updatedPortfolio = payload
  }),
  putUpdatePortfolio: thunk(async (actions, payload) => {
    const { index, data } = payload
    actions.setUpdatedPortfolio({ status: 1 })
    axios.put(`${API_URL}/common/users/self/portfolio/update/${index}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => actions.setUpdatedPortfolio({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedPortfolio({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Prepared
  portfolioPreparedImages: [],
  setPortfolioPreparedImages: action((state, payload = null) => {
    state.portfolioPreparedImages = payload
  }),
  // Old
  portfolioOldImages: [],
  setPortfolioOldImages: action((state, payload = null) => {
    state.portfolioOldImages = payload
  }),
  // Portfolio Images
  portfolioFormDataImages: [],
  setPortfolioFormDataImages: action((state, payload = null) => {
    state.portfolioFormDataImages = payload
  }),
  // DELETE Portfolio
  deletedPortfolio: { status: 0, payload: null },
  setDeletedPortfolio: action((state, payload = null) => {
    state.deletedPortfolio = payload
  }),
  deletePortfolio: thunk(async (actions, id) => {
    actions.setDeletedPortfolio({ status: 1 })
    axios.delete(`${API_URL}/common/users/self/portfolio/${id}`)
      .then(response => actions.setDeletedPortfolio({ status: 2, payload: response.data }))
      .catch(e => actions.v({ status: 3, payload: e.response.data }))
  }),
  // prepared video urls
  preparedVideoUrls: [],
  setPreparedVideoUrls: action((state, payload = null) => {
    state.preparedVideoUrls = payload
  }),
  allAttachments: null,
  setAllAttachments: action((state, payload = null) => {
    state.allAttachments = payload
  })
}
