
import React from 'react'
import { appendCloudFront } from '@/utils/helpers'
import SnedMessage from '@/assets/images/messages/uil_arrow-circle-up.png'
import AttachImage from '@/assets/images/messages/uil_image-v.png'
import AttachFile from '@/assets/images/messages/uil_file-alt.png'
// svg imports
import Download from '@/assets/svg/download-icon.svg'
import Info from '@/assets/svg/uil_info-circled.svg'
import Attachments from '@/assets/svg/uil_image-v.svg'
import Documents from '@/assets/svg/uil_file-alt.svg'
import CloseCircle from '@/assets/svg/uil_close-circled.svg'
import ArrowLeft from '@/assets/svg/uil_arrow-left.svg'
import Search from '@/assets/svg/uil_search.svg'
import ImagePlaceholder from '@/assets/svg/uil_orange-img-placeholder.svg'
import EmbedPlaceholder from '@/assets/svg/uil_orange-embed-placeholder.svg'
import Delete from '@/assets/svg/uil_delete.svg'
import CircleCheck from '@/assets/svg/uil_circle_check.svg'
import MessageWhite from '@/assets/svg/uil_message-white.svg'
import BriefcaseWhite from '@/assets/svg/uil_briefcase-white.svg'
import WhiteSearch from '@/assets/svg/uil_search-white.svg'
import WhiteHamburger from '@/assets/svg/uil_hamburger-white.svg'
import UploadGrey from '@/assets/svg/upload.svg'
import CircleCheckBlack from '@/assets/svg/uil_circle_check-black.svg'
// direct hire timeline svgs
import AlarmClock from '@/assets/svg/timeline-icons/clarity_alarm-clock-solid.svg'
import CalendarOne from '@/assets/svg/timeline-icons/clarity_calendar-solid.svg'
import CalendarTwo from '@/assets/svg/timeline-icons/clarity_calendar-2.svg'
import CalendarMonth from '@/assets/svg/timeline-icons/clarity_calendar-month.svg'
import CalendarUnknown from '@/assets/svg/timeline-icons/clarity_calendar-unknown.svg'
import OrangeLock from '@/assets/svg/uil_orange-lock.svg'

// export const ArrowLeftIcon = () => <img src={appendCloudFront('static/assets/images/icons/ArrowLeft.svg')} alt='arrow-left-icon' className='arrow-left' />
export const ArrowLeftIcon = () => <img src={ArrowLeft} alt='arrow-left-icon' className='arrow-left' />
export const UilBellIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-bell.svg')} alt='bell-icon' />
export const UilBriefcaseIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-briefcase.svg')} alt='brief-case-icon' />
export const UilEnvelopeIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-envelope-alt.svg')} alt='envelope-icon' />
export const UilArrowLeftIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-arrow-left.svg')} alt='arrow-left-icon' />
export const UilWhiteMessageIcon = props => <img src={MessageWhite} alt='' {...props} />
export const UilWhiteBriefcaseIcon = props => <img src={BriefcaseWhite} alt='' {...props} />
export const BankBriefCaseIcon = () => <img src={appendCloudFront('static/assets/images/icons/bank.svg')} alt='bank-brief-case' />
export const QuestionCircleIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-question-circle.svg')} alt='question-cirle' />
export const OrangeDownload = () => <img src={appendCloudFront('static/assets/images/icons/download.svg')} alt='orang-download' />
export const MobileStep1Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step-icon.svg')} alt='' />
export const MobileStep2Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step2-icon.svg')} alt='' />
export const MobileStep3Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step3-icon.svg')} alt='' />
export const PdfIcon = ({ width = 'auto', height = 'auto' }) => <img style={{ cursor: 'pointer' }} src={appendCloudFront('static/assets/images/pdf-file.svg')} alt='' width={width} height={height} />
export const UploadIcon = props => <img src={UploadGrey} alt='' {...props} />
export const CheckBlackIcon = props => <img src={CircleCheckBlack} {...props} />

// Icons for mobile
export const HamburgerIcon = () => <img src={appendCloudFront('static/assets/images/icons/hamburger-icon.svg')} alt='' />
export const MobileCloseIcon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-close-icon.svg')} alt='' />
export const MobileSearchIcon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-search-icon.svg')} alt='' />
export const WhiteSearchIcon = props => <img src={WhiteSearch} alt='' {...props} />
export const WhiteHamburgerIcon = props => <img src={WhiteHamburger} alt='' {...props} />

// revamped messages Icons
export const SendMessageIcon = () => <img src={SnedMessage} alt='' />
export const AttachImageIcon = () => <img src={AttachImage} alt='' />
export const AttachFileIcon = () => <img src={AttachFile} alt='' />

export const AttachmentsIcon = () => <img src={Attachments} alt='' />
export const DocumentsIcon = () => <img src={Documents} alt='' />
export const DownloadIcon = () => <img src={Download} alt='' />
export const InfoIcon = () => <img src={Info} alt='' />
export const CloseIcon = () => <img src={CloseCircle} alt='' />
export const SearchIcon = () => <img src={Search} alt='' />
export const DeleteIcon = () => <img src={Delete} alt='' />
export const CircleCheckIcon = () => <img src={CircleCheck} alt='' />

// orange Icons
export const ImgOrangePlaceholder = (props) => <img src={ImagePlaceholder} {...props} />
export const EmbedOrangePlaceholder = (props) => <img src={EmbedPlaceholder} {...props} />
export const OrangeLockIcon = (props) => <img src={OrangeLock} {...props} />

// timeline icons
export const AlarmClockIcon = props => <img src={AlarmClock} {...props} />
export const CalendarOneWeekIcon = props => <img src={CalendarOne} {...props} />
export const CalendarTwoWeeksIcon = props => <img src={CalendarTwo} {...props} />
export const CalendarMonthIcon = props => <img src={CalendarMonth} {...props} />
export const CalendarUnknownIcon = props => <img src={CalendarUnknown} {...props} />
