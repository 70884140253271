import styled from 'styled-components'
import { Carousel, Steps, Checkbox, Radio, Input, Modal } from 'antd'

export const PageContainer = styled.div`
  padding: 40px 104px;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 5px 24px;
  }
`

export const StyledLoader = styled.div`
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 40%;

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(238, 58, 66);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`

export const StyledLogoWhite = styled.div`
  position: fixed;
  top: 120px;
  left: 52px;

  img {
    width: 246px;
  }
`

export const StyledArtBy = styled.div`
  position: absolute;
  color: #fff;
  left: 52px;
  bottom: 100px;
  u {
    font-weight: bold;
  }
`

export const StyledCarouselMobileMenu = styled(Carousel)`
  overflow: scroll;
  margin-bottom: 20px;
  box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #f7f8fa;
  text-align: center;
  padding: 8px 0;

  a {
    display: inline-block;
    z-index: 2;
    padding: 4px 15px;
    min-width: 200px;
    font-size: 12px;
    color: var(--manila-bay-black) !important;
    font-weight: normal;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;
      /* font-weight: bold; */
    }
  }
`

export const StyledMobileMenuWrapper = styled.div`
  .anticon {
    font-size: 12px;
  }
  .prev {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 2px;
    left: 0;
  }

  .next {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 2px;
    right: 0;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

export const StyledMilestoneProgress = styled(Steps)`
  .ant-steps-item-icon {
    z-index: 2;
    height: 20px;
    width: 20px;
    margin: 0 0 8px -6px !important;
    .ant-steps-icon {
      display: none !important;
    }
  }
  .ant-steps-item-content {
    margin-left: 24px;
  }
  .ant-steps-item-tail {
    width: 2px;
    color: #9ba9ba;
  }
  .ant-steps-item-process,
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 2px solid var(--manila-bay-orange);
      background-color: var(--white);
      height: 20px;
      width: 20px;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border: 2px solid var(--coal-500);
    }
    .ant-steps-item-content {
      strong {
        color: var(--coal-500) !important;
      }
    }
  }
  .ant-steps-item-container {
    .ant-steps-item-tail {
      z-index: 1;
      padding: 0 0 8px;
      &::after {
        width: 2px !important;
        background-color: #9ba9ba;
      }
    }
  }
`

export const StyledOnGoingMilestoneTag = styled.div`
  font-size: 12px;
  border-radius: 100px;
  padding: 4px 8px;
  color: var(--white);
  background-color: var(--forest-green);
  text-align: center;
  margin: 0 10px;
`

export const StyledCustomCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    border: 1px solid #f06922;
    &::before {
      background-color: #f7fafc;
    }
    &::after {
      height: 11px;
    }
  }
`

export const StyledCustomRadioButton = styled(Radio)`
  display: block;
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #f06922;
    &::before {
      background-color: #f7fafc;
    }
    &::after {
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
  }
`

export const StyledSearch = styled(Input)`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    font-size: 20px;
    font-weight: 700;
  }
  input::placeholder {
    color: var(--manila-bay-orange);
  }
  .ant-input-affix-wrapper > input {
    background: #ffffff !important;
  }

  @media (max-width: 768px) {
    height: 40px;
  }
`
export const StyledRoundModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
`
